import doencas from '../files/doencas.json';

export function getSintomas() {
  return require('../files/sintomas.json');
};

export function getDoencas(sintomas) {
  return doencas.filter(x => sintomas.every(y => x.sintomas.includes(y)));
};

export function getDoenca(doenca) {
  return require(`../files/doencas/${doenca}.json`);
};