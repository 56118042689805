import PropTypes from 'prop-types';
import React, { Component } from 'react';
import '../App.css';
import axios from 'axios';
import Button from '@material-ui/core/Button';
import Card from '@material-ui/core/Card';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import Checkbox from '@material-ui/core/Checkbox';
import ExpansionPanel from '@material-ui/core/ExpansionPanel';
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails';
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormGroup from '@material-ui/core/FormGroup';
import InputAdornment from '@material-ui/core/InputAdornment';
import MenuItem from '@material-ui/core/MenuItem';
import { withStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { Link, Redirect } from 'react-router-dom';

const styles = theme => ({
  root: {
    width: '100%'
  },
  textField: {
    width: '90%',
    marginLeft: theme.spacing.unit,
    marginRight: theme.spacing.unit,
  },
  heading: {
    fontSize: theme.typography.pxToRem(15),
    fontWeight: theme.typography.fontWeightRegular,
  },
  checkboxes: {
    marginLeft: '15px',
    marginRight: '15px'
  }
});

const serverUrl = "https://tranquil-bayou-36721.herokuapp.com";

class Register extends Component {
  constructor(props) {
    super(props);
    this.state = {
      fichaPaciente: {},
      continueFilling: false,
      isRegistered: false,
      redirectToNanda: false
    };
  }

  componentDidMount() {
    let cpf = this.props.match.params.cpf;
    if(cpf !== 'new') {
      this.buscarPaciente(cpf);
    }
  }

  handleChange = (name) => event => {
    let value = event.target.value;

    switch (name) {
      case'cpf':
        if((!value || value.match(/^\d+$/)) && value.length < 12) {
          this.setState({
            fichaPaciente: { ...this.state.fichaPaciente, [name]: value },
          });
        }
        break;

      case 'idade':
      case 'temperatura':
        if((!value || value.match(/^\d+$/)) && value.length < 4) {
          this.setState({
            fichaPaciente: { ...this.state.fichaPaciente, [name]: value },
          })
        }
        break;
      
      case 'nome':
        if((!value || (value && !/^\d+$/.test(value)))) {
          this.setState({
            fichaPaciente: { ...this.state.fichaPaciente, [name]: value },
          })
        }
        break;

      case 'pressaoArterial':
        if((!value || value.match(/^\d+$/)) && value.length < 8) {
          this.setState({
            fichaPaciente: { ...this.state.fichaPaciente, [name]: value },
          })
        }
        break;

      case 'pulso':
        if(value.length < 4) {
          this.setState({
            fichaPaciente: { ...this.state.fichaPaciente, [name]: value },
          })
        }
        break;
      
      case 'noCateterPeriferico':
      case 'noCateterCentral':
      case 'noCateterVesical':
      case 'bulhas':
      case 'svdSisGen':
        if(!value || value.match(/^\d+$/)) {
          this.setState({
            fichaPaciente: { ...this.state.fichaPaciente, [name]: value },
          })
        }
        break;

      case 'dpoc':
      case 'cardiopatia':
      case 'has':
      case 'avc':
      case 'avch':
      case 'flebiteGenRis':
      case 'alergiaGenRis':
      case 'quedasGenRis':
      case 'lesaoGenRis':
      case 'broncoGenRis':
        if(this.state.fichaPaciente[name] === true){
          this.setState({
            fichaPaciente: { ...this.state.fichaPaciente, [name]: false },
          })
        }else{
          this.setState({
            fichaPaciente: { ...this.state.fichaPaciente, [name]: true },
          })          
        }
        // this.setState(prevState => ({
        //   fichaPaciente: { ...this.state.fichaPaciente, [name]: !prevState[name] },
        // }))
        // NAO TA FUNCIONANDO ESSE CURINTIA
        break;
      
      case 'continueFilling':
        this.setState(prevState => ({
          [name]: !prevState[name],
        }))
        break;

      default:
        this.setState({
          fichaPaciente: { ...this.state.fichaPaciente, [name]: value },
        })
        break;
    };
  };

  pesquisarNanda(){
    this.cadastrarPaciente();
    this.setState({
      redirectToNanda: true
    });
  }

  cadastrarPaciente(){
    var url = `${serverUrl}/cadastrarPaciente`;
    var json = JSON.stringify(this.state.fichaPaciente);
    if(this.state.fichaPaciente.cpf) {
      axios.post(url, {
        cpf: this.state.fichaPaciente.cpf,
        ficha: json
      })
      .then(function (response) {
        alert(response.data.status);
      })
      .catch(function (error) {
        console.log(error);
      });
    }else{
      alert("Você deve preencher o campo CPF.")
    }
    // COLOCAR UMA MENSAGEM DE PACIENTE CADASTRADO COM SUCESSO.
  };   

  buscarPaciente(cpf){
    var url = `${serverUrl}/buscarPaciente?CPF=${cpf}`;
    axios.get(url)
    .then((response) => {
      if(response.data.length > 0){
        this.setState({
          fichaPaciente: JSON.parse(response.data[0].Ficha),
          isRegistered: true
        });
      }else{
        alert("Registro não encontrado. Faça o cadastro.")
      }
      console.log(response);
    // TODO: COLOCAR UMA MENSAGEM DE PACIENTE ENCONTRADO COM SUCESSO.
    })
  };

  atualizarPaciente(e){
    e.preventDefault();
    var url = `${serverUrl}/atualizarPaciente`;
    var json = JSON.stringify(this.state.fichaPaciente);
    axios.put(url, {
      cpf: this.state.fichaPaciente.cpf,
      ficha: json
    })
    .then(function (response) {
      // console.log(response);
      alert(response.data.status);
    })
    .catch(function (error) {
      console.log(error);
    });
  };

  deletarPaciente(e){
    e.preventDefault();
    var url = `${serverUrl}/deletarPaciente`;
    axios.post(url, {
      cpf: this.state.fichaPaciente.cpf
    })
    .then(function (/* response */) {
      // console.log(response);
    })
    .catch(function (error) {
      console.log(error);
    });
    // COLOCAR UMA MENSAGEM DE PACIENTE DELETADO COM SUCESSO.
  };

  render() {
    const { classes } = this.props;

    const { redirectToNanda, fichaPaciente } = this.state;

    return (
      <div>
        {redirectToNanda && fichaPaciente &&
          <Redirect to={ `/search/${fichaPaciente.cpf}` } />
        }
        <div className="Title">
          <img src="../images/marca_puc_minas.png" alt="PucMinasLogo" style={{ width: "80px", marginTop: "20px" }} />
          <h2>CADASTRO</h2>
        </div>
        <Card className="App">
          <CardContent>
            <form className={classes.root} noValidate autoComplete="off">
              <ExpansionPanel defaultExpanded>
                <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
                  <Typography className={classes.heading}>Dados Pessoais</Typography>
                </ExpansionPanelSummary>
                <ExpansionPanelDetails style={{ display: 'block', padding: '0px', paddingBottom: '5px'}}>
                  <TextField
                    id="datetime-local"
                    label="Data/Hora de Entrada"
                    type="datetime-local"
                    value={this.state.fichaPaciente.date}
                    // defaultValue="2017-05-24T10:30"
                    onChange={this.handleChange('date')}
                    className={classes.textField}
                    variant="outlined"
                    InputLabelProps={{
                      shrink: true,
                    }}
                  />
                  <TextField
                    id="outlined-cpf"
                    label="CPF"
                    value={this.state.fichaPaciente.cpf}
                    onChange={this.handleChange('cpf')}
                    type="number"
                    className={classes.textField}
                    InputLabelProps={{
                      shrink: true,
                    }}
                    margin="normal"
                    variant="outlined"
                    placeholder="Digite o CPF"
                    InputProps={{
                      readOnly: this.state.isRegistered ? true : false,
                    }}
                    required
                  />
                  <TextField
                    id="outlined-nome-input"
                    label="Nome Completo"
                    value={this.state.fichaPaciente.nome}
                    onChange={this.handleChange('nome')}
                    className={classes.textField}
                    InputLabelProps={{
                      shrink: true,
                    }}
                    type="text"
                    name="nome"
                    margin="normal"
                    placeholder="Digite o nome completo"
                    variant="outlined"
                  />
                  <TextField
                    id="outlined-idade"
                    label="Idade"
                    value={this.state.fichaPaciente.idade}
                    onChange={this.handleChange('idade')}
                    type="number"
                    className={classes.textField}
                    InputLabelProps={{
                      shrink: true,
                    }}
                    margin="normal"
                    variant="outlined"
                    placeholder="Digite a idade"
                  />
                  <TextField
                    id="outlined-select-gender"
                    select
                    label="Sexo"
                    className={classes.textField}
                    value={this.state.fichaPaciente.genero}
                    onChange={this.handleChange('genero')}
                    InputLabelProps={{
                      shrink: true,
                    }}
                    margin="normal"
                    variant="outlined"
                  >
                    <MenuItem value={'Masculino'}>Masculino</MenuItem>
                    <MenuItem value={'Feminino'}>Feminino</MenuItem>
                    <MenuItem value={'Outro'}>Outro</MenuItem>
                  </TextField>
                  <TextField
                    id="outlined-setor-input"
                    label="Setor"
                    placeholder="Digite o setor"
                    onChange={this.handleChange('setor')}
                    name="setor"
                    multiline
                    value={this.state.fichaPaciente.setor}
                    className={classes.textField}
                    margin="normal"
                    variant="outlined"
                    InputLabelProps={{
                      shrink: true,
                    }}
                  />
                </ExpansionPanelDetails>
              </ExpansionPanel>
              <ExpansionPanel>
                <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
                  <Typography className={classes.heading}>Doença e história Progressiva</Typography>
                </ExpansionPanelSummary>
                <ExpansionPanelDetails style={{ display: 'block', padding: '0px', paddingBottom: '5px'}}>
                  <FormGroup row className={classes.checkboxes}>
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={this.state.fichaPaciente.cardiopatia === true ? true : false}
                          onChange={this.handleChange('cardiopatia')}
                          color="primary"
                        />
                      }
                      label="Cardiopatia"
                    />
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={this.state.fichaPaciente.dpoc === true ? true : false}
                          onChange={this.handleChange('dpoc')}
                          color="primary"
                        />
                      }
                      label="DPOC"
                    />
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={this.state.fichaPaciente.has === true ? true : false}
                          onChange={this.handleChange('has')}
                          color="primary"
                        />
                      }
                      label="HAS"
                    />
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={this.state.fichaPaciente.avc === true ? true : false}
                          onChange={this.handleChange('avc')}
                          color="primary"
                        />
                      }
                      label="AVC"
                    />
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={this.state.fichaPaciente.avch === true ? true : false}
                          onChange={this.handleChange('avch')}
                          color="primary"
                        />
                      }
                      label="AVCH"
                    />
                  </FormGroup>
                  <TextField
                    id="outlined-diabeteMellitus-input"
                    select
                    label="Diabete Mellitus?"
                    className={classes.textField}
                    value={this.state.fichaPaciente.diabeteMellitus}
                    onChange={this.handleChange('diabeteMellitus')}
                    InputLabelProps={{
                      shrink: true,
                    }}
                    margin="normal"
                    variant="outlined"
                  >
                    <MenuItem value={''}></MenuItem>
                    <MenuItem value={'1'}>1</MenuItem>
                    <MenuItem value={'2'}>2</MenuItem>
                  </TextField>
                  <TextField
                    id="outlined-tireoide-input"
                    select
                    label="Tireoide?"
                    className={classes.textField}
                    value={this.state.fichaPaciente.tireoide}
                    onChange={this.handleChange('tireoide')}
                    InputLabelProps={{
                      shrink: true,
                    }}
                    margin="normal"
                    variant="outlined"
                  >
                    <MenuItem value={'Normal'}>Normal</MenuItem>
                    <MenuItem value={'Hipertireoidismo'}>Hipertireoidismo</MenuItem>
                    <MenuItem value={'Hipotireoidismo'}>Hipotireoidismo</MenuItem>
                  </TextField>
                  <div className={classes.textField}>
                    <TextField
                      id="outlined-nanda-input"
                      label="Outros"
                      placeholder="Pesquisar no NANDA"
                      name="outros"
                      multiline
                      value={this.state.fichaPaciente.nandaLink}
                      style={{ width: '60%' }}
                      margin="normal"
                      variant="outlined"
                      InputLabelProps={{
                        shrink: true,
                      }}
                      InputProps={{
                        readOnly: true,
                      }}
                    />
                    <Button size="small" variant="outlined" style={{ width: '40%', marginTop: '25px'}} onClick={this.pesquisarNanda.bind(this)}>Pesquisar</Button>
                  </div>
                  <TextField
                    id="outlined-alergias-input"
                    label="Alergias"
                    placeholder="Digite aqui"
                    onChange={this.handleChange('alergias')}
                    name="alergias"
                    multiline
                    value={this.state.fichaPaciente.alergias}
                    className={classes.textField}
                    margin="normal"
                    variant="outlined"
                    InputLabelProps={{
                      shrink: true,
                    }}
                  />
                  <TextField
                    id="farmacos-de-uso-domiciliario"
                    label="Farmacos de uso domiciliario"
                    placeholder="Doses/miligramas"
                    onChange={this.handleChange('farmacos')}
                    name="alergias"
                    multiline
                    value={this.state.fichaPaciente.farmacos}
                    className={classes.textField}
                    margin="normal"
                    variant="outlined"
                    InputLabelProps={{
                      shrink: true,
                    }}
                  />
                  <TextField
                    id="cirurgias-progressivas"
                    label="Cirurgias progressivas"
                    placeholder="Digite aqui"
                    onChange={this.handleChange('cirurgiasProg')}
                    name="cirurgiasProg"
                    multiline
                    value={this.state.fichaPaciente.cirurgiasProg}
                    className={classes.textField}
                    margin="normal"
                    variant="outlined"
                    InputLabelProps={{
                      shrink: true,
                    }}
                  />
                  <TextField
                    label="Pressão arterial"
                    id="pressao-arterial"
                    variant="outlined"
                    className={classes.textField}
                    onChange={this.handleChange('pressaoArterial')}
                    value={this.state.fichaPaciente.pressaoArterial}
                    margin="normal"
                    InputProps={{
                      endAdornment: <InputAdornment position="end">mmHg</InputAdornment>,
                    }}
                    InputLabelProps={{
                      shrink: true,
                    }}
                  />
                  <TextField
                    label="Pulso"
                    id="pulso"
                    variant="outlined"
                    className={classes.textField}
                    onChange={this.handleChange('pulso')}
                    value={this.state.fichaPaciente.pulso}
                    margin="normal"
                    InputProps={{
                      endAdornment: <InputAdornment position="end">bpm</InputAdornment>,
                    }}
                    InputLabelProps={{
                      shrink: true,
                    }}
                  />
                  <TextField
                    label="SPO²"
                    id="spo"
                    variant="outlined"
                    className={classes.textField}
                    onChange={this.handleChange('spo')}
                    value={this.state.fichaPaciente.spo}
                    margin="normal"
                    InputProps={{
                      endAdornment: <InputAdornment position="end">bpm</InputAdornment>,
                    }}
                    InputLabelProps={{
                      shrink: true,
                    }}
                  />
                  <TextField
                    label="Temperatura"
                    id="temperatura"
                    variant="outlined"
                    className={classes.textField}
                    onChange={this.handleChange('temperatura')}
                    value={this.state.fichaPaciente.temperatura}
                    margin="normal"
                    InputProps={{
                      endAdornment: <InputAdornment position="end">°C</InputAdornment>,
                    }}
                    InputLabelProps={{
                      shrink: true,
                    }}
                  />
                </ExpansionPanelDetails>
              </ExpansionPanel>
              <ExpansionPanel>
                <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
                  <Typography className={classes.heading}>Dispositivos</Typography>
                </ExpansionPanelSummary>
                <ExpansionPanelDetails style={{ display: 'block', padding: '0px', paddingBottom: '5px'}}>
                  <TextField
                    label="Nº Catéter Periférico"
                    id="numero-cateter-periferico"
                    variant="outlined"
                    className={classes.textField}
                    onChange={this.handleChange('noCateterPeriferico')}
                    value={this.state.fichaPaciente.noCateterPeriferico}
                    margin="normal"
                    InputLabelProps={{
                      shrink: true,
                    }}
                  />
                  <TextField
                    id="local-cateter-periferico"
                    select
                    label="Local Catéter Periférico"
                    className={classes.textField}
                    value={this.state.fichaPaciente.loCateterPeriferico}
                    onChange={this.handleChange('loCateterPeriferico')}
                    InputLabelProps={{
                      shrink: true,
                    }}
                    margin="normal"
                    variant="outlined"
                  >
                    <MenuItem value={''}></MenuItem>
                    <MenuItem value={'MSE'}>MSE</MenuItem>
                    <MenuItem value={'MSD'}>MSD</MenuItem>
                    <MenuItem value={'MIE'}>MIE</MenuItem>
                    <MenuItem value={'MID'}>MID</MenuItem>
                  </TextField>
                  <TextField
                    label="Nº Catéter Central"
                    id="numero-cateter-central"
                    variant="outlined"
                    className={classes.textField}
                    onChange={this.handleChange('noCateterCentral')}
                    value={this.state.fichaPaciente.noCateterCentral}
                    margin="normal"
                    InputLabelProps={{
                      shrink: true,
                    }}
                  />
                  <TextField
                    label="Local Catéter Central"
                    id="local-cateter-central"
                    variant="outlined"
                    className={classes.textField}
                    onChange={this.handleChange('loCateterCentral')}
                    value={this.state.fichaPaciente.loCateterCentral}
                    margin="normal"
                    InputLabelProps={{
                      shrink: true,
                    }}
                  />
                  <TextField
                    label="Nº Catéter Vesical"
                    id="numero-cateter-vesical"
                    variant="outlined"
                    className={classes.textField}
                    onChange={this.handleChange('noCateterVesical')}
                    value={this.state.fichaPaciente.noCateterVesical}
                    margin="normal"
                    InputLabelProps={{
                      shrink: true,
                    }}
                  />
                </ExpansionPanelDetails>
              </ExpansionPanel>
              { this.state.continueFilling &&
                <div>
                  <ExpansionPanel>
                    <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
                      <Typography className={classes.heading}>Sistema Neurológico</Typography>
                    </ExpansionPanelSummary>
                    <ExpansionPanelDetails style={{ display: 'block', padding: '0px', paddingBottom: '5px'}}>
                      <TextField
                        id="glasgow"
                        select
                        label="Glasgow"
                        className={classes.textField}
                        value={this.state.fichaPaciente.glasgow}
                        onChange={this.handleChange('glasgow')}
                        InputLabelProps={{
                          shrink: true,
                        }}
                        margin="normal"
                        variant="outlined"
                      >
                        <MenuItem value={''}></MenuItem>
                        <MenuItem value={'1'}>1</MenuItem>
                        <MenuItem value={'2'}>2</MenuItem>
                        <MenuItem value={'3'}>3</MenuItem>
                        <MenuItem value={'4'}>4</MenuItem>
                        <MenuItem value={'5'}>5</MenuItem>
                        <MenuItem value={'6'}>6</MenuItem>
                        <MenuItem value={'7'}>7</MenuItem>
                        <MenuItem value={'8'}>8</MenuItem>
                        <MenuItem value={'9'}>9</MenuItem>
                        <MenuItem value={'10'}>10</MenuItem>
                        <MenuItem value={'11'}>11</MenuItem>
                        <MenuItem value={'12'}>12</MenuItem>
                        <MenuItem value={'13'}>13</MenuItem>
                        <MenuItem value={'14'}>14</MenuItem>
                        <MenuItem value={'15'}>15</MenuItem>
                      </TextField>
                      <TextField
                        id="ransay"
                        select
                        label="Ransay"
                        className={classes.textField}
                        value={this.state.fichaPaciente.ransay}
                        onChange={this.handleChange('ransay')}
                        InputLabelProps={{
                          shrink: true,
                        }}
                        margin="normal"
                        variant="outlined"
                      >
                        <MenuItem value={''}></MenuItem>
                        <MenuItem value={'1'}>1</MenuItem>
                        <MenuItem value={'2'}>2</MenuItem>
                        <MenuItem value={'3'}>3</MenuItem>
                        <MenuItem value={'4'}>4</MenuItem>
                        <MenuItem value={'5'}>5</MenuItem>
                        <MenuItem value={'6'}>6</MenuItem>
                      </TextField>
                      <TextField
                        id="estado-sistema-neu"
                        select
                        label="Estado"
                        className={classes.textField}
                        value={this.state.fichaPaciente.estadoSistemaNeu}
                        onChange={this.handleChange('estadoSistemaNeu')}
                        InputLabelProps={{
                          shrink: true,
                        }}
                        margin="normal"
                        variant="outlined"
                      >
                        <MenuItem value={''}></MenuItem>
                        <MenuItem value={'Consciente'}>Consciente</MenuItem>
                        <MenuItem value={'Torposo'}>Torposo</MenuItem>
                        <MenuItem value={'Orientado'}>Orientado</MenuItem>
                        <MenuItem value={'Desorientado'}>Desorientado</MenuItem>
                        <MenuItem value={'Comatoso'}>Comatoso</MenuItem>
                        <MenuItem value={'Agitado'}>Agitado</MenuItem>
                      </TextField>
                      <TextField
                        label="Défict Motor?"
                        id="defict-motor"
                        variant="outlined"
                        className={classes.textField}
                        onChange={this.handleChange('defictMotor')}
                        value={this.state.fichaPaciente.defictMotor}
                        margin="normal"
                        InputLabelProps={{
                          shrink: true,
                        }}
                      />
                      <TextField
                        label="Défict Sensorial?"
                        id="defict-sensorial"
                        variant="outlined"
                        className={classes.textField}
                        onChange={this.handleChange('defictSensorial')}
                        value={this.state.fichaPaciente.defictSensorial}
                        margin="normal"
                        InputLabelProps={{
                          shrink: true,
                        }}
                      />
                      <TextField
                        id="pupilas"
                        select
                        label="Pupilas"
                        className={classes.textField}
                        value={this.state.fichaPaciente.pupilas}
                        onChange={this.handleChange('pupilas')}
                        InputLabelProps={{
                          shrink: true,
                        }}
                        margin="normal"
                        variant="outlined"
                      >
                        <MenuItem value={''}></MenuItem>
                        <MenuItem value={'Miose'}>Miose</MenuItem>
                        <MenuItem value={'Midriase'}>Midriase</MenuItem>
                        <MenuItem value={'Isocória'}>Isocória</MenuItem>
                        <MenuItem value={'Anisocória'}>Anisocória</MenuItem>
                        <MenuItem value={'Fotoreação'}>Fotoreação</MenuItem>
                      </TextField>
                    </ExpansionPanelDetails>
                  </ExpansionPanel>
                  <ExpansionPanel>
                    <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
                      <Typography className={classes.heading}>Sistema Cardiovascular</Typography>
                    </ExpansionPanelSummary>
                    <ExpansionPanelDetails style={{ display: 'block', padding: '0px', paddingBottom: '5px'}}>
                      <TextField
                        label="Pressão arterial"
                        variant="outlined"
                        className={classes.textField}
                        onChange={this.handleChange('pressaoArterial')}
                        value={this.state.fichaPaciente.pressaoArterial}
                        margin="normal"
                        InputProps={{
                          endAdornment: <InputAdornment position="end">mmHg</InputAdornment>,
                        }}
                        InputLabelProps={{
                          shrink: true,
                        }}
                      />
                      <TextField
                        id="pulso-sis-car"
                        select
                        label="Pulso"
                        className={classes.textField}
                        value={this.state.fichaPaciente.pulsoSisCar}
                        onChange={this.handleChange('pulsoSisCar')}
                        InputLabelProps={{
                          shrink: true,
                        }}
                        margin="normal"
                        variant="outlined"
                      >
                        <MenuItem value={''}></MenuItem>
                        <MenuItem value={'Ritimico'}>Ritimico</MenuItem>
                        <MenuItem value={'Arritimico'}>Arritimico</MenuItem>
                        <MenuItem value={'Cheio'}>Cheio</MenuItem>
                        <MenuItem value={'Filifirme'}>Filifirme</MenuItem>
                      </TextField>
                      <TextField
                        id="perfusao-periferica"
                        select
                        label="Perfusão Periférica"
                        className={classes.textField}
                        value={this.state.fichaPaciente.perfusaoPeriferica}
                        onChange={this.handleChange('perfusaoPeriferica')}
                        InputLabelProps={{
                          shrink: true,
                        }}
                        margin="normal"
                        variant="outlined"
                      >
                        <MenuItem value={''}></MenuItem>
                        <MenuItem value={'Normal'}>Normal</MenuItem>
                        <MenuItem value={'Diminuída'}>Diminuída</MenuItem>
                      </TextField>
                      <TextField
                        id="auscuta"
                        select
                        label="Auscuta"
                        className={classes.textField}
                        value={this.state.fichaPaciente.auscuta}
                        onChange={this.handleChange('auscuta')}
                        InputLabelProps={{
                          shrink: true,
                        }}
                        margin="normal"
                        variant="outlined"
                      >
                        <MenuItem value={''}></MenuItem>
                        <MenuItem value={'Normofonéticas'}>Normofonéticas</MenuItem>
                        <MenuItem value={'Hiperfonéticas'}>Hiperfonéticas</MenuItem>
                        <MenuItem value={'Hipofonéticas'}>Hipofonéticas</MenuItem>
                      </TextField>
                      <TextField
                        id="bulhas"
                        label="Bulhas"
                        variant="outlined"
                        className={classes.textField}
                        onChange={this.handleChange('bulhas')}
                        value={this.state.fichaPaciente.bulhas}
                        margin="normal"
                        InputProps={{
                          endAdornment: <InputAdornment position="end">T</InputAdornment>,
                        }}
                        InputLabelProps={{
                          shrink: true,
                        }}
                      />
                      <TextField
                        id="edema-mse"
                        select
                        label="Edema em MSE"
                        className={classes.textField}
                        value={this.state.fichaPaciente.edemaMse}
                        onChange={this.handleChange('edemaMse')}
                        InputLabelProps={{
                          shrink: true,
                        }}
                        margin="normal"
                        variant="outlined"
                      >
                        <MenuItem value={''}></MenuItem>
                        <MenuItem value={'+'}>+</MenuItem>
                        <MenuItem value={'++'}>++</MenuItem>
                        <MenuItem value={'+++'}>+++</MenuItem>
                        <MenuItem value={'++++'}>++++</MenuItem>
                      </TextField>
                      <TextField
                        id="edema-msd"
                        select
                        label="Edema em MSD"
                        className={classes.textField}
                        value={this.state.fichaPaciente.edemaMsd}
                        onChange={this.handleChange('edemaMsd')}
                        InputLabelProps={{
                          shrink: true,
                        }}
                        margin="normal"
                        variant="outlined"
                      >
                        <MenuItem value={''}></MenuItem>
                        <MenuItem value={'+'}>+</MenuItem>
                        <MenuItem value={'++'}>++</MenuItem>
                        <MenuItem value={'+++'}>+++</MenuItem>
                        <MenuItem value={'++++'}>++++</MenuItem>
                      </TextField>
                      <TextField
                        id="edema-mie"
                        select
                        label="Edema em MIE"
                        className={classes.textField}
                        value={this.state.fichaPaciente.edemaMie}
                        onChange={this.handleChange('edemaMie')}
                        InputLabelProps={{
                          shrink: true,
                        }}
                        margin="normal"
                        variant="outlined"
                      >
                        <MenuItem value={''}></MenuItem>
                        <MenuItem value={'+'}>+</MenuItem>
                        <MenuItem value={'++'}>++</MenuItem>
                        <MenuItem value={'+++'}>+++</MenuItem>
                        <MenuItem value={'++++'}>++++</MenuItem>
                      </TextField>
                      <TextField
                        id="edema-mid"
                        select
                        label="Edema em MID"
                        className={classes.textField}
                        value={this.state.fichaPaciente.edemaMid}
                        onChange={this.handleChange('edemaMid')}
                        InputLabelProps={{
                          shrink: true,
                        }}
                        margin="normal"
                        variant="outlined"
                      >
                        <MenuItem value={''}></MenuItem>
                        <MenuItem value={'+'}>+</MenuItem>
                        <MenuItem value={'++'}>++</MenuItem>
                        <MenuItem value={'+++'}>+++</MenuItem>
                        <MenuItem value={'++++'}>++++</MenuItem>
                      </TextField>
                      <TextField
                        id="sinais-de-empastamento"
                        select
                        label="Sinais de Empastamento de MMII"
                        className={classes.textField}
                        value={this.state.fichaPaciente.empastamentoMmii}
                        onChange={this.handleChange('empastamentoMmii')}
                        InputLabelProps={{
                          shrink: true,
                        }}
                        margin="normal"
                        variant="outlined"
                      >
                        <MenuItem value={''}></MenuItem>
                        <MenuItem value={'Direito'}>Direito</MenuItem>
                        <MenuItem value={'Esquerdo'}>Esquerdo</MenuItem>
                      </TextField>
                    </ExpansionPanelDetails>
                  </ExpansionPanel>
                  <ExpansionPanel>
                    <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
                      <Typography className={classes.heading}>Sistema Respiratório</Typography>
                    </ExpansionPanelSummary>
                    <ExpansionPanelDetails style={{ display: 'block', padding: '0px', paddingBottom: '5px'}}>
                      <TextField
                        id="estado-sis-resp"
                        select
                        label="Estado"
                        className={classes.textField}
                        value={this.state.fichaPaciente.estadoSisResp}
                        onChange={this.handleChange('estadoSisResp')}
                        InputLabelProps={{
                          shrink: true,
                        }}
                        margin="normal"
                        variant="outlined"
                      >
                        <MenuItem value={''}></MenuItem>
                        <MenuItem value={'Eupineico'}>Eupineico</MenuItem>
                        <MenuItem value={'Dispineico'}>Dispineico</MenuItem>
                        <MenuItem value={'Taquipineico'}>Taquipineico</MenuItem>
                        <MenuItem value={'Bradpineico'}>Bradpineico</MenuItem>
                      </TextField>
                      <TextField
                        id="tipo-resp"
                        select
                        label="Tipo de Respiração"
                        className={classes.textField}
                        value={this.state.fichaPaciente.tipoResp}
                        onChange={this.handleChange('tipoResp')}
                        InputLabelProps={{
                          shrink: true,
                        }}
                        margin="normal"
                        variant="outlined"
                      >
                        <MenuItem value={''}></MenuItem>
                        <MenuItem value={'Superficial'}>Superficial</MenuItem>
                        <MenuItem value={'Paradoxal'}>Paradoxal</MenuItem>
                        <MenuItem value={'Musculatura Acessória'}>Musculatura Acessória</MenuItem>
                      </TextField>
                      <TextField
                        id="suporte-oxigenio"
                        select
                        label="Suporte de Oxigênio"
                        className={classes.textField}
                        value={this.state.fichaPaciente.suporteOxigenio}
                        onChange={this.handleChange('suporteOxigenio')}
                        InputLabelProps={{
                          shrink: true,
                        }}
                        margin="normal"
                        variant="outlined"
                      >
                        <MenuItem value={''}></MenuItem>
                        <MenuItem value={'Máscara'}>Máscara</MenuItem>
                        <MenuItem value={'Nebulização'}>Nebulização</MenuItem>
                        <MenuItem value={'Vent. Mecânica'}>Vent. Mecânica</MenuItem>
                        <MenuItem value={'VNI/Intermitente'}>VNI/Intermitente</MenuItem>
                      </TextField>
                      <TextField
                        id="austuca-sis-resp"
                        select
                        label="Auscuta Respiração"
                        className={classes.textField}
                        value={this.state.fichaPaciente.auscutaSisResp}
                        onChange={this.handleChange('auscutaSisResp')}
                        InputLabelProps={{
                          shrink: true,
                        }}
                        margin="normal"
                        variant="outlined"
                      >
                        <MenuItem value={''}></MenuItem>
                        <MenuItem value={'MV+ s/RA'}>MV+ s/RA</MenuItem>
                        <MenuItem value={'MV+'}>MV+</MenuItem>
                        <MenuItem value={'Roncos'}>Roncos</MenuItem>
                        <MenuItem value={'Estertores'}>Estertores</MenuItem>
                        <MenuItem value={'Sibilos'}>Sibilos</MenuItem>
                      </TextField>
                      <TextField
                        id="inspecao-sis-resp"
                        select
                        label="Inspeção"
                        className={classes.textField}
                        value={this.state.fichaPaciente.inspecaoSisResp}
                        onChange={this.handleChange('inspecaoSisResp')}
                        InputLabelProps={{
                          shrink: true,
                        }}
                        margin="normal"
                        variant="outlined"
                      >
                        <MenuItem value={''}></MenuItem>
                        <MenuItem value={'Simétrico'}>Simétrico</MenuItem>
                        <MenuItem value={'Assimético'}>Assimético</MenuItem>
                        <MenuItem value={'Citose'}>Citose</MenuItem>
                        <MenuItem value={'Tonei'}>Tonei</MenuItem>
                        <MenuItem value={'Quilha'}>Quilha</MenuItem>
                        <MenuItem value={'Outros'}>Outros</MenuItem>
                      </TextField>
                      <TextField
                        id="abdome-sis-reps"
                        select
                        label="Abdome"
                        className={classes.textField}
                        value={this.state.fichaPaciente.abdomeSisResp}
                        onChange={this.handleChange('abdomeSisResp')}
                        InputLabelProps={{
                          shrink: true,
                        }}
                        margin="normal"
                        variant="outlined"
                      >
                        <MenuItem value={''}></MenuItem>
                        <MenuItem value={'RHA+'}>RHA+</MenuItem>
                        <MenuItem value={'Normoativo'}>Normoativo</MenuItem>
                        <MenuItem value={'Hiperativo'}>Hiperativo</MenuItem>
                        <MenuItem value={'Hipoativo'}>Hipoativo</MenuItem>
                      </TextField>
                      <TextField
                        id="alimentacao-sis-reps"
                        select
                        label="Alimentação"
                        className={classes.textField}
                        value={this.state.fichaPaciente.alimentacaoSisResp}
                        onChange={this.handleChange('alimentacaoSisResp')}
                        InputLabelProps={{
                          shrink: true,
                        }}
                        margin="normal"
                        variant="outlined"
                      >
                        <MenuItem value={''}></MenuItem>
                        <MenuItem value={'Jejum'}>Jejum</MenuItem>
                        <MenuItem value={'SNE'}>SNE</MenuItem>
                        <MenuItem value={'SNG'}>SNG</MenuItem>
                        <MenuItem value={'VO'}>VO</MenuItem>
                      </TextField>
                    </ExpansionPanelDetails>
                  </ExpansionPanel>
                  <ExpansionPanel>
                    <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
                      <Typography className={classes.heading}>Sistema Gastrointestinal</Typography>
                    </ExpansionPanelSummary>
                    <ExpansionPanelDetails style={{ display: 'block', padding: '0px', paddingBottom: '5px'}}>
                      <TextField
                        id="incisao-cirurgica-sis-gast"
                        label="Incisão Cirúrgica"
                        className={classes.textField}
                        value={this.state.fichaPaciente.incisaoCirurgicaSisGas}
                        onChange={this.handleChange('incisaoCirurgicaSisGas')}
                        InputLabelProps={{
                          shrink: true,
                        }}
                        margin="normal"
                        variant="outlined"
                      />
                      <TextField
                        id="ostomias-sis-gast"
                        label="Ostomias"
                        className={classes.textField}
                        value={this.state.fichaPaciente.ostomiasSisGas}
                        onChange={this.handleChange('ostomiasSisGas')}
                        InputLabelProps={{
                          shrink: true,
                        }}
                        margin="normal"
                        variant="outlined"
                      />
                      <TextField
                        id="inspecao-sis-gast"
                        select
                        label="Inspeção"
                        className={classes.textField}
                        value={this.state.fichaPaciente.inspecaoSisGas}
                        onChange={this.handleChange('inspecaoSisGas')}
                        InputLabelProps={{
                          shrink: true,
                        }}
                        margin="normal"
                        variant="outlined"
                      >
                        <MenuItem value={''}></MenuItem>
                        <MenuItem value={'Flácido'}>Flácido</MenuItem>
                        <MenuItem value={'Globoso'}>Globoso</MenuItem>
                        <MenuItem value={'Distendido'}>Distendido</MenuItem>
                        <MenuItem value={'Escavado'}>Escavado</MenuItem>
                      </TextField>
                      <TextField
                        id="percurssao-sis-gast"
                        select
                        label="Percurssão"
                        className={classes.textField}
                        value={this.state.fichaPaciente.percurssaoSisGas}
                        onChange={this.handleChange('percurssao')}
                        InputLabelProps={{
                          shrink: true,
                        }}
                        margin="normal"
                        variant="outlined"
                      >
                        <MenuItem value={''}></MenuItem>
                        <MenuItem value={'Timpânico'}>Timpânico</MenuItem>
                        <MenuItem value={'Piparote'}>Piparote</MenuItem>
                      </TextField>
                      <TextField
                        id="palpacao-sis-gast"
                        label="Palpação"
                        className={classes.textField}
                        value={this.state.fichaPaciente.palpacaoSisGas}
                        onChange={this.handleChange('palpacaoSisGas')}
                        InputLabelProps={{
                          shrink: true,
                        }}
                        margin="normal"
                        variant="outlined"
                      />
                      <TextField
                        id="evacuacoes-sis-gast"
                        label="Evacuações"
                        className={classes.textField}
                        value={this.state.fichaPaciente.evacuacoesSisGas}
                        onChange={this.handleChange('evacuacoesSisGas')}
                        InputLabelProps={{
                          shrink: true,
                        }}
                        margin="normal"
                        variant="outlined"
                      />
                    </ExpansionPanelDetails>
                  </ExpansionPanel>
                  <ExpansionPanel>
                    <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
                      <Typography className={classes.heading}>Sistema Genitourinário</Typography>
                    </ExpansionPanelSummary>
                    <ExpansionPanelDetails style={{ display: 'block', padding: '0px', paddingBottom: '5px'}}>
                      <TextField
                        id="diurese-sis-gen"
                        select
                        label="Diurese"
                        className={classes.textField}
                        value={this.state.fichaPaciente.diureseSisGen}
                        onChange={this.handleChange('diureseSisGen')}
                        InputLabelProps={{
                          shrink: true,
                        }}
                        margin="normal"
                        variant="outlined"
                      >
                        <MenuItem value={''}></MenuItem>
                        <MenuItem value={'Espontânea'}>Espontânea</MenuItem>
                        <MenuItem value={'Fralda'}>Fralda</MenuItem>
                        <MenuItem value={'SVA'}>SVA</MenuItem>
                        <MenuItem value={'Hematúria'}>Hematúria</MenuItem>
                        <MenuItem value={'Colúria'}>Colúria</MenuItem>
                        <MenuItem value={'Poliúria'}>Poliúria</MenuItem>
                      </TextField>
                      <TextField
                        label="SVD"
                        id="svd-sis-gen"
                        variant="outlined"
                        className={classes.textField}
                        onChange={this.handleChange('svdSisGen')}
                        value={this.state.fichaPaciente.svdSisGen}
                        margin="normal"
                        InputLabelProps={{
                          shrink: true,
                        }}
                      />
                      <TextField
                        label="Outros"
                        id="outros-sis-gen"
                        variant="outlined"
                        className={classes.textField}
                        onChange={this.handleChange('outrosSisGen')}
                        value={this.state.fichaPaciente.outrosSisGen}
                        margin="normal"
                        InputLabelProps={{
                          shrink: true,
                        }}
                      />
                      <TextField
                        label="Genitália"
                        id="genitalia-sis-gen"
                        variant="outlined"
                        className={classes.textField}
                        onChange={this.handleChange('genitaliaSisGen')}
                        value={this.state.fichaPaciente.genitaliaSisGen}
                        margin="normal"
                        InputLabelProps={{
                          shrink: true,
                        }}
                      />
                    </ExpansionPanelDetails>
                  </ExpansionPanel>
                  <ExpansionPanel>
                    <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
                      <Typography className={classes.heading}>Sistema Tegumentar</Typography>
                    </ExpansionPanelSummary>
                    <ExpansionPanelDetails style={{ display: 'block', padding: '0px', paddingBottom: '5px'}}>
                      <TextField
                        id="estado-sis-teg"
                        select
                        label="Estado"
                        className={classes.textField}
                        value={this.state.fichaPaciente.estadoSisTeg}
                        onChange={this.handleChange('estadoSisTeg')}
                        InputLabelProps={{
                          shrink: true,
                        }}
                        margin="normal"
                        variant="outlined"
                      >
                        <MenuItem value={''}></MenuItem>
                        <MenuItem value={'Normocorado'}>Normocorado</MenuItem>
                        <MenuItem value={'Anictérico'}>Anictérico</MenuItem>
                        <MenuItem value={'Acianótico'}>Acianótico</MenuItem>
                        <MenuItem value={'Cianótico'}>Cianótico</MenuItem>
                      </TextField>
                      <TextField
                        id="hipocorado-sis-teg"
                        select
                        label="Hipocorado"
                        className={classes.textField}
                        value={this.state.fichaPaciente.hipocoradoSisTeg}
                        onChange={this.handleChange('hipocoradoSisTeg')}
                        InputLabelProps={{
                          shrink: true,
                        }}
                        margin="normal"
                        variant="outlined"
                      >
                        <MenuItem value={''}></MenuItem>
                        <MenuItem value={'+'}>+</MenuItem>
                        <MenuItem value={'++'}>++</MenuItem>
                        <MenuItem value={'+++'}>+++</MenuItem>
                        <MenuItem value={'++++'}>++++</MenuItem>
                      </TextField>
                      <TextField
                        id="icterio-sis-teg"
                        select
                        label="Ictérico"
                        className={classes.textField}
                        value={this.state.fichaPaciente.icterioSisTeg}
                        onChange={this.handleChange('icterioSisTeg')}
                        InputLabelProps={{
                          shrink: true,
                        }}
                        margin="normal"
                        variant="outlined"
                      >
                        <MenuItem value={''}></MenuItem>
                        <MenuItem value={'+'}>+</MenuItem>
                        <MenuItem value={'++'}>++</MenuItem>
                        <MenuItem value={'+++'}>+++</MenuItem>
                        <MenuItem value={'++++'}>++++</MenuItem>
                      </TextField>
                      <TextField
                        id="turgor-sis-teg"
                        select
                        label="Turgor"
                        className={classes.textField}
                        value={this.state.fichaPaciente.turgorSisTeg}
                        onChange={this.handleChange('turgorSisTeg')}
                        InputLabelProps={{
                          shrink: true,
                        }}
                        margin="normal"
                        variant="outlined"
                      >
                        <MenuItem value={''}></MenuItem>
                        <MenuItem value={'Preservado'}>Preservado</MenuItem>
                        <MenuItem value={'Diminuido'}>Diminuido</MenuItem>
                      </TextField>
                      <TextField
                        label="LPP"
                        id="lpp-sis-teg"
                        variant="outlined"
                        className={classes.textField}
                        onChange={this.handleChange('lppSisTeg')}
                        value={this.state.fichaPaciente.lppSisTeg}
                        margin="normal"
                        InputLabelProps={{
                          shrink: true,
                        }}
                      />
                      <TextField
                        id="graulpp-sis-teg"
                        select
                        label="Grau LPP"
                        className={classes.textField}
                        value={this.state.fichaPaciente.grauLppSisTeg}
                        onChange={this.handleChange('grauLppSisTeg')}
                        InputLabelProps={{
                          shrink: true,
                        }}
                        margin="normal"
                        variant="outlined"
                      >
                        <MenuItem value={''}></MenuItem>
                        <MenuItem value={'Grau I'}>Grau I</MenuItem>
                        <MenuItem value={'Grau II'}>Grau II</MenuItem>
                        <MenuItem value={'Grau III'}>Grau III</MenuItem>
                      </TextField>
                    </ExpansionPanelDetails>
                  </ExpansionPanel>
                  <ExpansionPanel>
                    <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
                      <Typography className={classes.heading}>Sistema Locomotor</Typography>
                    </ExpansionPanelSummary>
                    <ExpansionPanelDetails style={{ display: 'block', padding: '0px', paddingBottom: '5px'}}>
                    <TextField
                        id="mmss-sis-loc"
                        select
                        label="MMSS"
                        className={classes.textField}
                        value={this.state.fichaPaciente.mmssSisLoc}
                        onChange={this.handleChange('mmssSisLoc')}
                        InputLabelProps={{
                          shrink: true,
                        }}
                        margin="normal"
                        variant="outlined"
                      >
                        <MenuItem value={''}></MenuItem>
                        <MenuItem value={'Simétrico'}>Simétrico</MenuItem>
                        <MenuItem value={'Assimético'}>Assimético</MenuItem>
                      </TextField>
                      <TextField
                        id="forca-mmss-sis-loc"
                        select
                        label="Força Muscular MMSS"
                        className={classes.textField}
                        value={this.state.fichaPaciente.forcaMmssSisLoc}
                        onChange={this.handleChange('forcaMmssSisLoc')}
                        InputLabelProps={{
                          shrink: true,
                        }}
                        margin="normal"
                        variant="outlined"
                      >
                        <MenuItem value={''}></MenuItem>
                        <MenuItem value={'+'}>+</MenuItem>
                        <MenuItem value={'-'}>-</MenuItem>
                      </TextField>
                      <TextField
                        id="atividade-mmss-sis-loc"
                        select
                        label="Atividade Motora MMSS"
                        className={classes.textField}
                        value={this.state.fichaPaciente.atividadeMmssSisLoc}
                        onChange={this.handleChange('atividadeMmssSisLoc')}
                        InputLabelProps={{
                          shrink: true,
                        }}
                        margin="normal"
                        variant="outlined"
                      >
                        <MenuItem value={''}></MenuItem>
                        <MenuItem value={'+'}>+</MenuItem>
                        <MenuItem value={'-'}>-</MenuItem>
                      </TextField>
                      <TextField
                        id="mmii-sis-loc"
                        select
                        label="MMII"
                        className={classes.textField}
                        value={this.state.fichaPaciente.mmiiSisLoc}
                        onChange={this.handleChange('mmiiSisLoc')}
                        InputLabelProps={{
                          shrink: true,
                        }}
                        margin="normal"
                        variant="outlined"
                      >
                        <MenuItem value={''}></MenuItem>
                        <MenuItem value={'Simétrico'}>Simétrico</MenuItem>
                        <MenuItem value={'Assimético'}>Assimético</MenuItem>
                      </TextField>
                      <TextField
                        id="forca-mmii-sis-loc"
                        select
                        label="Força Muscular MMII"
                        className={classes.textField}
                        value={this.state.fichaPaciente.forcaMmiiSisLoc}
                        onChange={this.handleChange('forcaMmiiSisLoc')}
                        InputLabelProps={{
                          shrink: true,
                        }}
                        margin="normal"
                        variant="outlined"
                      >
                        <MenuItem value={''}></MenuItem>
                        <MenuItem value={'+'}>+</MenuItem>
                        <MenuItem value={'-'}>-</MenuItem>
                      </TextField>
                      <TextField
                        id="atividade-mmii-sis-loc"
                        select
                        label="Atividade Motora MMII"
                        className={classes.textField}
                        value={this.state.fichaPaciente.atividadeMmiiSisLoc}
                        onChange={this.handleChange('atividadeMmiiSisLoc')}
                        InputLabelProps={{
                          shrink: true,
                        }}
                        margin="normal"
                        variant="outlined"
                      >
                        <MenuItem value={''}></MenuItem>
                        <MenuItem value={'+'}>+</MenuItem>
                        <MenuItem value={'-'}>-</MenuItem>
                      </TextField>
                    </ExpansionPanelDetails>
                  </ExpansionPanel>
                  <ExpansionPanel>
                    <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
                      <Typography className={classes.heading}>Gerenciamento de Risco</Typography>
                    </ExpansionPanelSummary>
                    <ExpansionPanelDetails style={{ display: 'block', padding: '0px', paddingBottom: '5px'}}>
                      <FormGroup row className={classes.checkboxes}>
                        <FormControlLabel
                          control={
                            <Checkbox
                              checked={this.state.fichaPaciente.flebiteGenRis === true ? true : false}
                              onChange={this.handleChange('flebiteGenRis')}
                              color="primary"
                            />
                          }
                          label="Flebite"
                        />
                        <FormControlLabel
                          control={
                            <Checkbox
                              checked={this.state.fichaPaciente.alergiaGenRis === true ? true : false}
                              onChange={this.handleChange('alergiaGenRis')}
                              color="primary"
                            />
                          }
                          label="Alergia"
                        />
                        <FormControlLabel
                          control={
                            <Checkbox
                              checked={this.state.fichaPaciente.quedasGenRis === true ? true : false}
                              onChange={this.handleChange('quedasGenRis')}
                              color="primary"
                            />
                          }
                          label="Quedas"
                        />
                        <FormControlLabel
                          control={
                            <Checkbox
                              checked={this.state.fichaPaciente.lesaoGenRis === true ? true : false}
                              onChange={this.handleChange('lesaoGenRis')}
                              color="primary"
                            />
                          }
                          label="Lesão por Pressão"
                        />
                        <FormControlLabel
                          control={
                            <Checkbox
                              checked={this.state.fichaPaciente.broncoGenRis === true ? true : false}
                              onChange={this.handleChange('broncoGenRis')}
                              color="primary"
                            />
                          }
                          label="Broncoaspiração"
                        />
                      </FormGroup>
                    </ExpansionPanelDetails>
                  </ExpansionPanel>
                </div>
              }
            </form>
            <FormControlLabel
              control={
                <Checkbox
                  checked={this.state.continueFilling}
                  onChange={this.handleChange('continueFilling')}
                  color="primary"
                />
              }
              label="Gostaria de continuar preenchendo?"
            />
          </CardContent>
          <CardActions>
            <Link to="/" style={{ textDecoration: "none", marginRight: '5px' }}>
              <Button size="small" variant="outlined">Voltar</Button>
            </Link>
            {
              this.state.isRegistered ?
                <Button size="small" variant="outlined" onClick={this.atualizarPaciente.bind(this)}>Atualizar</Button> :
                <Button size="small" variant="outlined" onClick={this.cadastrarPaciente.bind(this)}>Cadastrar</Button>
            }
          </CardActions>
        </Card>
      </div>
    );
  }
}

Register.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(Register);
