import React, { Component } from "react";
import "../App.css";
import axios from 'axios';
import { Link, Redirect } from "react-router-dom";
import Card from "@material-ui/core/Card";
import CardActions from "@material-ui/core/CardActions";
import CardContent from "@material-ui/core/CardContent";
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";
import { getDoenca } from "../actions/actions";

const serverUrl = "https://tranquil-bayou-36721.herokuapp.com";

class DiseaseSearch extends Component {
  constructor(props) {
    super(props);
    this.state = {
      doenca: {},
      fichaPaciente: {},
      isRegistered: false,
      redirectRegister: false
    };
  }

  componentDidMount() {
    let cpf = this.props.match.params.cpf;
    if(cpf !== 'new') {
      this.buscarPaciente(cpf);
    }

    if (this.props.location.state) {
      const doencaId = this.props.location.state.doenca;
      const doencaDetalhe = getDoenca(doencaId);
      this.setState({ doenca: doencaDetalhe });
    }
  }

  buscarPaciente(cpf){
    var url = `${serverUrl}/buscarPaciente?CPF=${cpf}`;
    axios.get(url)
    .then((response) => {
      if(response.data.length > 0){
        this.setState({
          fichaPaciente: JSON.parse(response.data[0].Ficha),
          isRegistered: true
        });
        this.setState({
          fichaPaciente: { ...this.state.fichaPaciente, nandaLink: this.state.doenca.nome },
        })
      }
      // console.log(response);
    // TODO: COLOCAR UMA MENSAGEM DE PACIENTE ENCONTRADO COM SUCESSO.
    })
  };

  atualizarPaciente(e){
    e.preventDefault();
    
    var url = `${serverUrl}/atualizarPaciente`;
    var json = JSON.stringify(this.state.fichaPaciente);
    axios.put(url, {
      cpf: this.state.fichaPaciente.cpf,
      ficha: json
    })
    .then(function (response) {
      // console.log(response);
      alert(response.data.status);
      this.setState({
        redirectRegister: true
      });
    })
    .catch(function (error) {
      console.log(error);
    });
  };

  render() {

    const { match } = this.props;
    
    return (
      <div>
        {
          this.state.redirectRegister && match &&
          <Redirect to={`/register/${match.params.cpf}`} />
        }
        <div className="Title">
          <img src="../images/marca_puc_minas.png" alt="PucMinasLogo" style={{ width: "80px", marginTop: "20px" }} />
          <h2>DOENÇA</h2>
        </div>
        <Card className="App">
          <CardContent>
            {this.state.doenca && <Typography color="textSecondary" gutterBottom>
              { this.state.doenca.nome && <h2>{ this.state.doenca.nome }</h2> }<br/>
              { this.state.doenca.definicao &&
                <div>
                  <h3>Definição</h3>
                  <p>{ this.state.doenca.definicao }</p>
                  <br/>
                </div>
              }
              { this.state.doenca.caracteristicasDefinidoras &&
                <div>
                  <h3>Características Definidoras</h3>
                  { this.state.doenca.caracteristicasDefinidoras.map((x) => (
                    <p>{ x.toString() }</p>
                  ))}
                  <br/>
                </div>
              }
              { this.state.doenca.fatoresDeRisco &&
                <div>
                  <h3>Fatores de Risco</h3>
                  { this.state.doenca.fatoresDeRisco.map((x) => (
                    <p>{ x.toString() }</p>
                  ))}
                  <br/>
                </div>
              }
              { this.state.doenca.fatoresRelacionados &&
                <div>
                  <h3>Fatores Relacionados</h3>
                  { this.state.doenca.fatoresRelacionados.map((x) => (
                    <p>{ x.toString() }</p>
                  ))}
                  <br/>
                </div>
              }
              { this.state.doenca.intervencoesPrincipais &&
                <div>
                  <h3>Intervenções Principais</h3>
                  { this.state.doenca.intervencoesPrincipais.map((x) => (
                    <p>{ x.toString() }</p>
                  ))}
                  <br/>
                </div>
              }
              { this.state.doenca.intervencoesSugeridas &&
                <div>
                  <h3>Intervenções Sugeridas</h3>
                  { this.state.doenca.intervencoesSugeridas.map((x) => (
                    <p>{ x.toString() }</p>
                  ))}
                  <br/>
                </div>
              }
            </Typography> }
          </CardContent>
          <CardActions>
            <Link exact to="/" style={{ textDecoration: "none" }}>
              <Button size="small" variant="outlined">Voltar</Button>
            </Link>
            { this.state.isRegistered &&
              <Button size="small" variant="outlined" onClick={this.atualizarPaciente.bind(this)}>Anexar</Button>
            }            
          </CardActions>
        </Card>
      </div>
    );
  }
}

export default DiseaseSearch;
