import React, { Component } from 'react';
import '../App.css';
import { Link } from 'react-router-dom';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';

class Home extends Component {

  render() {

    return (
      <div>
        <div className="Title">
          <img src="../images/marca_puc_minas.png" alt="PucMinasLogo" style={{ width: "80px", marginTop: "20px" }} />
          <h2>BEM VINDO(A)</h2>
        </div>
        <Card className="App">
          <CardContent>
            <Typography color="textSecondary" gutterBottom>
              Escolha a ação desejada:
            </Typography>
            <br />
            <br />
            <div style={{ textAlign: 'center' }}>
              <Link to="/consult" style={{ textDecoration: "none" }}>
                <Button size="small" variant="outlined">CONSULTAR</Button>
              </Link>
              <br />
              <br />
              <Link to="/register/new" style={{ textDecoration: "none" }}>
                <Button size="small" variant="outlined">CADASTRAR PACIENTE</Button>
              </Link>
              <br />
              <br />
              <Link to="/search/new" style={{ textDecoration: "none" }}>
                <Button size="small" variant="outlined">PESQUISAR DOENÇA</Button>
              </Link>
            </div>
          </CardContent>
        </Card>
      </div>
    );
  }
}

export default Home;
