import React, { Component } from 'react';
import '../App.css';
import { BrowserRouter as Router, Route } from 'react-router-dom';
import Home from './Home';
import Search from './Search';
import Register from './Register';
import Consult from './Consult';
import Disease from './Disease';
import DiseaseSearch from './DiseaseSearch';

class App extends Component {
  render (){
    return( 
      <Router>
        <div>
          <Route exact path="/" component={Home} />
          <Route path="/search/:cpf" component={Search} />
          <Route path="/register/:cpf" component={Register} />
          <Route path="/consult" component={Consult} />
          <Route path="/disease/:cpf" component={Disease} />
          <Route path="/diseaseSearch/:cpf" component={DiseaseSearch} />
        </div>
      </Router>
    );
  }
}

export default App;
