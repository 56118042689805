import React, { Component } from "react";
import "../App.css";
import { Link } from "react-router-dom";
import Card from "@material-ui/core/Card";
import CardActions from "@material-ui/core/CardActions";
import CardContent from "@material-ui/core/CardContent";
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";
import { getDoencas } from "../actions/actions";

class DiseaseSearch extends Component {
  constructor(props) {
    super(props);
    this.state = {
      sintomas: [],
      doencas: []
    };
  }

  componentDidMount() {
    if (this.props.location.state) {
      const sintomas = this.props.location.state.sintomas;
      const doencas = getDoencas(sintomas.map(x => x.value));
      this.setState({ sintomas, doencas });
    }
  }

  render() {
    return (
      <div>
        <div className="Title">
          <img src="../images/marca_puc_minas.png" alt="PucMinasLogo" style={{ width: "80px", marginTop: "20px" }} />
          <h2>DOENÇAS ENCONTRADAS:</h2>
        </div>
        <Card className="App">
          <CardContent>
            <Typography color="textSecondary" gutterBottom>
              { this.state.doencas.length === 0 ?
                <h3 style={{ textAlign: 'center' }}>Nenhuma doença encontrada com os sintomas escolhidos!</h3>
              :
                this.state.doencas.map((x) => (
                  <h3>
                    <Link style={{ textDecoration: 'none', color: 'black' }} to={{ pathname: `/disease/${this.props.match.params.cpf}`, state: { doenca: x.id } }} >
                        { x.nome.toString() }
                    </Link>
                  </h3>
                ))
              }
            </Typography>
          </CardContent>
          <CardActions>
            <Link exact to="/" style={{ textDecoration: "none" }}>
              <Button size="small" variant="outlined">Voltar</Button>
            </Link>
          </CardActions>
        </Card>
      </div>
    );
  }
}

export default DiseaseSearch;
