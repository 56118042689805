import React, { Component } from 'react';
import '../App.css';
import { Link } from 'react-router-dom';
import AutoComplete from './AutoComplete';
import Card from '@material-ui/core/Card';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';

class Search extends Component {
  constructor(props) {
    super(props);
    this.state = {
      sintomas: []
    };

    this.handleAutoCompleteChange = this.handleAutoCompleteChange.bind(this);
  }

  handleAutoCompleteChange = value => {
    this.setState({ sintomas: value });
  };

  render() {

    return (
      <div>
        <div className="Title">
          <img src="../images/marca_puc_minas.png" alt="PucMinasLogo" style={{ width: "80px", marginTop: "20px" }} />
          <h2>BEM VINDO(A)</h2>
        </div>
        <Card className="App">
          <CardContent>
            <Typography color="textSecondary" gutterBottom>
              Digite o(s) sintoma(s):
            </Typography>
            <br />
            <br />
            <AutoComplete
              selectOption={ this.handleAutoCompleteChange }
              values={ this.state.sintomas }
            />
          </CardContent>
          <CardActions>
            <Link to="/" style={{ textDecoration: "none" }}>
              <Button size="small" variant="outlined">Voltar</Button>
            </Link>
            <Link to={{ pathname: `/diseaseSearch/${this.props.match.params.cpf}`, state: { sintomas: this.state.sintomas } }} style={{ textDecoration: "none" }} >
              <Button size="small" variant="outlined">Pesquisar</Button>
            </Link>
          </CardActions>
        </Card>
      </div>
    );
  }
}

export default Search;
