import React, { Component } from 'react';
import '../App.css';
import { Link } from 'react-router-dom';
import Card from '@material-ui/core/Card';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import { withStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';

const styles = theme => ({
  textField: {
    width: '90%',
    marginLeft: theme.spacing.unit,
    marginRight: theme.spacing.unit,
  }
});

class Consult extends Component {
  constructor(props) {
    super(props);
    this.state = {
      cpf: ''
    };
  }
  
  handleChange = (name) => event => {
    let value = event.target.value;

    if((!value || value.match(/^\d+$/)) && value.length < 12) {
      this.setState({
        [name]: value,
      });
    }
  };

  render() {
    const { classes } = this.props;

    return (
      <div>
        <div className="Title">
          <img src="../images/marca_puc_minas.png" alt="PucMinasLogo" style={{ width: "80px", marginTop: "20px" }} />
          <h2>CONSULTAR PACIENTE</h2>
        </div>
        <Card className="App">
          <CardContent>
            <Typography color="textSecondary" gutterBottom>
              Digite o CPF do paciente:
            </Typography>
            <br />
            <br />
            <TextField
              id="outlined-cpf"
              label="CPF"
              value={this.state.cpf}
              onChange={this.handleChange('cpf')}
              type="number"
              className={classes.textField}
              InputLabelProps={{
                shrink: true,
              }}
              margin="normal"
              variant="outlined"
              placeholder="Digite somente números do CPF"
              required
            />
          </CardContent>
          <CardActions>
            <Link to="/" style={{ textDecoration: "none" }}>
              <Button size="small" variant="outlined">Voltar</Button>
            </Link>
            <Link to={{ pathname: `/register/${this.state.cpf}` }} style={{ textDecoration: "none" }} >
              <Button size="small" variant="outlined">Buscar Paciente</Button>
            </Link>
          </CardActions>
        </Card>
      </div>
    );
  }
}

export default withStyles(styles)(Consult);
